var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "masthead",
        { staticClass: "masthead", attrs: { datasource: null } },
        [
          _c(
            "b-button",
            {
              class: { "mr-2 rounded": !_vm.hasSmallScreen },
              attrs: {
                variant: "link",
                to: "/documents",
                size: "$mq",
                "aria-label": "Back button",
              },
            },
            [
              _c("feather-icon", { attrs: { type: "chevron-left" } }),
              _vm._v("\n      Back\n    "),
            ],
            1
          ),
          _c(
            "b-button",
            {
              class: { "mr-2 rounded": !_vm.hasSmallScreen },
              attrs: {
                variant: "primary",
                size: "$mq",
                "aria-label": "Print document button",
              },
              on: { click: _vm.print },
            },
            [
              _c("feather-icon", { attrs: { type: "printer" } }),
              _vm._v("\n      Print\n    "),
            ],
            1
          ),
          _c(
            "b-button",
            {
              class: { "rounded btn-shadow": !_vm.hasSmallScreen },
              attrs: {
                variant: "primary",
                "aria-label": "Download document button",
              },
              on: { click: _vm.downloadAndLog },
            },
            [
              _c("feather-icon", { attrs: { type: "download" } }),
              _vm._v("\n      Download\n    "),
            ],
            1
          ),
          _vm.loaded && _vm.doc.documentCanBeRequested
            ? _c(
                "b-button",
                {
                  class: { "rounded btn-shadow": !_vm.hasSmallScreen },
                  attrs: {
                    variant: "primary",
                    "aria-label": "Request Document button",
                  },
                  on: { click: _vm.requestDocument },
                },
                [
                  _c("feather-icon", { attrs: { type: "mail" } }),
                  _vm._v("\n      Request Document\n    "),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("hr", { staticClass: "mb-4 w-100" }),
      _vm.pageNumber <= 200 && !_vm.hideDocumentView
        ? _c(
            "b-container",
            { staticClass: "md-2 sm-4 img-container border-bottom" },
            [
              _c("b-img", {
                staticClass: "client-document-page",
                attrs: {
                  src: _vm.imageURL,
                  "blank-color": "#bbb",
                  alt: _vm.documentTitle,
                },
              }),
            ],
            1
          )
        : _c("div", [
            _vm.hideDocumentView
              ? _c("div", [
                  _vm._v(
                    '\n      Please select "Download" to view the document\n    '
                  ),
                ])
              : _c("div", [
                  _vm._v(
                    '\n      This is an oversized document. Please select "Download" to view the document in entirety\n    '
                  ),
                ]),
          ]),
      _vm.isAcceptedQuoteDocument
        ? _c(
            "p",
            [
              _vm._v("\n    This quote has been signed and accepted.\n    "),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n    Signature:\n    " +
                  _vm._s(_vm.signature.first_name) +
                  " " +
                  _vm._s(_vm.signature.last_name) +
                  "\n    "
              ),
              _c("br"),
              _c("b-img", {
                attrs: {
                  thumbnail: "",
                  fluid: "",
                  src: _vm.signature.file.join(),
                  alt: "Can't find image",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-button-group",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showQuoteDialog && !_vm.isAcceptedQuoteDocument,
              expression: "showQuoteDialog && !isAcceptedQuoteDocument",
            },
          ],
          staticClass: "row center-buttons",
        },
        [
          _c(
            "b-button",
            {
              class: "btn-shadow rounded max-button",
              attrs: {
                disabled: _vm.quoteStatus,
                variant: "primary",
                "aria-label": "Accept button",
              },
              on: { click: _vm.getSignatureBeforeAccepting },
            },
            [
              _c("feather-icon", { attrs: { type: "check-circle" } }),
              _vm._v("\n      Accept\n    "),
            ],
            1
          ),
          _c(
            "b-button",
            {
              class: "btn-shadow rounded max-button",
              attrs: {
                disabled: _vm.quoteStatus,
                variant: "danger",
                "aria-label": "Decline button",
              },
              on: {
                click: function ($event) {
                  return _vm.setAcceptOrDeclineQuote("mark_declined")
                },
              },
            },
            [
              _c("feather-icon", { attrs: { type: "x" } }),
              _vm._v("\n      Decline\n    "),
            ],
            1
          ),
          _c(
            "b-button",
            {
              class: "btn-shadow rounded max-button",
              attrs: {
                disabled: _vm.quoteStatus,
                variant: "secondary",
                "aria-label": "Remind me later button",
              },
              on: { click: _vm.remindMeLater },
            },
            [
              _c("feather-icon", { attrs: { type: "alert-triangle" } }),
              _vm._v("\n      Remind me later\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "mb-4 w-100" }),
      _vm.loaded && _vm.doc.pages && _vm.doc.pages.length > 0
        ? _c("b-pagination-nav", {
            staticClass: "custom-pagination",
            attrs: {
              "base-url": `/#/documents/${_vm.doc ? _vm.doc.id : ""}/`,
              "number-of-pages":
                _vm.doc && _vm.doc.pages ? _vm.doc.pages.length : 1,
              align: "center",
              page: _vm.pageNumber,
            },
            on: { change: _vm.scrollUp },
            model: {
              value: _vm.pageNumber,
              callback: function ($$v) {
                _vm.pageNumber = $$v
              },
              expression: "pageNumber",
            },
          })
        : _vm._e(),
      _vm.showQuoteDialog
        ? _c("signature-modal", {
            attrs: { "company-id": _vm.companyID },
            on: { "signing-complete": _vm.handleSigning },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }