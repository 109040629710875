<template>
  <b-container>
    <masthead
      class="masthead"
      :datasource="null"
    >
      <b-button
        variant="link"
        to="/documents"
        size="$mq"
        aria-label="Back button"
        :class="{'mr-2 rounded': !hasSmallScreen}"
      >
        <feather-icon type="chevron-left" />
        Back
      </b-button>
      <b-button
        variant="primary"
        size="$mq"
        aria-label="Print document button"
        :class="{'mr-2 rounded': !hasSmallScreen}"
        @click="print"
      >
        <feather-icon type="printer" />
        Print
      </b-button>
      <b-button
        variant="primary"
        aria-label="Download document button"
        :class="{'rounded btn-shadow': !hasSmallScreen}"
        @click="downloadAndLog"
      >
        <feather-icon type="download" />
        Download
      </b-button>

      <b-button
        v-if="loaded && doc.documentCanBeRequested"
        variant="primary"
        aria-label="Request Document button"
        :class="{'rounded btn-shadow': !hasSmallScreen}"
        @click="requestDocument"
      >
        <feather-icon type="mail" />
        Request Document
      </b-button>
    </masthead>
    <hr class="mb-4 w-100">
    <b-container
      v-if="pageNumber <= 200 && !hideDocumentView"
      class="md-2 sm-4 img-container border-bottom"
    >
      <b-img
        :src="imageURL"
        :blank-color="'#bbb'"
        class="client-document-page"
        :alt="documentTitle"
      />
    </b-container>
    <div v-else>
      <div v-if="hideDocumentView">
        Please select "Download" to view the document
      </div>
      <div v-else>
        This is an oversized document. Please select "Download" to view the document in entirety
      </div>
    </div>
    <p v-if="isAcceptedQuoteDocument">
      This quote has been signed and accepted.
      <br>
      <br>
      Signature:
      {{ signature.first_name }} {{ signature.last_name }}
      <br>
      <b-img thumbnail fluid :src="signature.file.join()" alt="Can't find image" />
    </p>
    <b-button-group
      v-show="showQuoteDialog && !isAcceptedQuoteDocument"
      class="row center-buttons"
    >
      <b-button
        :class="'btn-shadow rounded max-button'"
        :disabled="quoteStatus"
        variant="primary"
        aria-label="Accept button"
        @click="getSignatureBeforeAccepting"
      >
        <feather-icon type="check-circle" />
        Accept
      </b-button>
      <b-button
        :class="'btn-shadow rounded max-button'"
        :disabled="quoteStatus"
        variant="danger"
        aria-label="Decline button"
        @click="setAcceptOrDeclineQuote('mark_declined')"
      >
        <feather-icon type="x" />
        Decline
      </b-button>
      <b-button
        :class="'btn-shadow rounded max-button'"
        :disabled="quoteStatus"
        variant="secondary"
        aria-label="Remind me later button"
        @click="remindMeLater"
      >
        <feather-icon type="alert-triangle" />
        Remind me later
      </b-button>
    </b-button-group>
    <hr class="mb-4 w-100">
    <b-pagination-nav
      v-if="loaded && doc.pages && doc.pages.length > 0"
      v-model="pageNumber"
      :base-url="`/#/documents/${doc ? doc.id : ''}/`"
      :number-of-pages="doc && doc.pages ? doc.pages.length : 1"
      align="center"
      class="custom-pagination"
      :page="pageNumber"
      @change="scrollUp"
    />

    <signature-modal
      v-if="showQuoteDialog"
      :company-id="companyID"
      @signing-complete="handleSigning"
    />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FeatherIcon from '@/components/shared/FeatherIcon'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { documentViewMixin } from '@/mixins/documentViewMixin'
import SignatureModal from '@/components/SignatureModal'
import {
  createOrFindClientInteractionLog,
  logClientInteraction,
} from '@/common/modules/clientInteractionLog'
import Masthead from '@/components/shared/Masthead.vue'

export default {
  name: 'Document',
  components: { Masthead, FeatherIcon, SignatureModal },
  mixins: [
    makeToastMixin,
    documentViewMixin,
  ],
  data: function() {
    return {
      loaded: false,
      documents: null,
      signature: null,
      hideDocumentView: false,
    }
  },
  computed: {
    ...mapGetters('creditCardProcessing', ['quoteStatus', 'signatureId']),
    ...mapGetters('signatures', ['signatures']),
    token: function() {
      return (
        this.$store.state.session.token !== undefined &&
        this.$store.state.session.token !== null
      )
    },
    isAcceptedQuoteDocument() {
      return this.doc?.status === 'accepted'
    },
    showQuoteDialog() { return this.doc?.type === 'Credit Card Quote' },
    companyID() { return this.doc.companyId },
    documentTitle() {
      const altTitle = 'Document image'
      if (!this.documents.length) return altTitle

      const document = this.documents[0]
      return document?.title || altTitle
    },
    hasSmallScreen() {
      return this.$mq === 'sm'
    },
  },
  watch: {
    pageNumber: function() {
      this.loadPage()
    },
  },
  async mounted() {
    this.doc = await this.$store.dispatch('documents/fetchId', {
      id: this.$route.params.id,
    })
    await this.loadPage()
    this.loaded = true
    this.documents = this.$store.getters['documents/getAll']
    this.getQuoteDecision()
    if (this.isAcceptedQuoteDocument){
      await this.setSignatureForQuoteDocument()
    }
    this.hideDocumentView = this.doc.title.includes('Cease and Desist Document') || this.doc?.title.includes('Trademark Application Document')
  },
  methods: {
    ...mapActions('creditCardProcessing', ['acceptOrDeclineQuote', 'getQuoteStatus', 'getSignatureIdForQuoteDocument']),
    ...mapActions('signatures', ['getSignatures']),
    ...mapActions('ephemeralMessage', ['createMessage']),
    goBack() {
      this.$router.go(-1)
    },
    async setSignatureForQuoteDocument(){
      await this.getSignatures({ companyId: this.doc.companyId })
      await this.getSignatureIdForQuoteDocument({ documentID: this.doc.id } )

      this.signature = this.signatures.find(sig => sig.id === this.signatureId)
    },
    async requestDocument() {
      await this.$store.dispatch('requestDocument/setSelectedDocumentId', { id: this.$route.params.id })
      await this.$store.dispatch('requestDocument/setSelectedDocumentCompanyId', { companyId: this.doc.companyId })
      await this.$router.push({ name: 'documents' })
    },
    getQuoteDecision() {
      this.getQuoteStatus({ documentID: this.doc.id })
    },
    getSignatureBeforeAccepting() {
      this.$bvModal.show('signature-modal')
    },
    handleSigning() {
      this.$bvModal.hide('signature-modal')
      this.successToast('Success', 'Document has been Signed!')
      this.setAcceptOrDeclineQuote('mark_accepted')
    },
    async setAcceptOrDeclineQuote(selectedRoute) {
      try {
        await this.acceptOrDeclineQuote(
          { documentID: this.doc.id, selectedRoute: selectedRoute }
        )
        this.successToast('Success', 'Thank you for submitting your decision')
      } catch {
        this.errorToast('Error', 'There seems to be a problem')
      }
    },
    async remindMeLater() {
      await this.createMessage({
        body: 'Reminder: View your Credit Card Processing quote under your “documents” tab.',
        messageType: 'quote-reminder',
        docId: this.doc.id,
      }).then(() => {
        this.successToast('Success', 'We will remind you again about this quote')
      })
    },
    async logInteraction(type, action, name, companyId, objectId) {
      try {
        this.clientInteractionLog = await createOrFindClientInteractionLog({
          category: 'documents',
          subCategory: '',
          objectId: objectId,
          objectTable: 'ClientDocument',
          companyId: companyId,
        })
        this.clientInteractionLog = await logClientInteraction({
          clientInteractionLogId: this.clientInteractionLog.id,
          interaction: { type, action, name },
        })
      } catch (error) { /* eslint-disable no-empty */
      } /* eslint-enable no-empty */
    },
    async downloadAndLog() {
      await this.logInteraction('button', 'download', 'download-document', this.doc.companyId, this.doc.id)
      await this.download()
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-pagination {
  float: center !important;
  position: relative !important;
  width: 100% !important;
}

.img-container {
  text-align: center;
  display: flex;
  align-items: center;
}

.border-bottom {
  margin-bottom: 2em;
  border-bottom: 1px solid black;
}

.center-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 3.75em;
}

.max-button {
  max-width: 300px;
}

.client-document-page {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .client-document-page {
    width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  ::v-deep .toolbar-row-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.0rem;

    .btn {
      width: 100%;
    }
  }

  .img-container {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
